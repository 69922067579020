<template>
  <div class="box" :class="classN">
    <button :data-type="$props.type" class="non" v-if="!isMember" @click="showSSO">
      <span class="desktop">{{ $props.cta }}</span>
      <span class="mobile">Become a member for free and access exclusive deals</span>
      <span class="join" >Join now!</span>
    </button>
    <button v-if="isMember" class="mem">
      Signed in for member discount!
    </button>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isMember: document.cookie.indexOf("member_cookie") > -1
    }
  },
  props: {
    classN: String,
    cta: String,
    type: String
  },
  methods: {
    showSSO() {
      let type, code,
          track = document.querySelector(".visitor_track"),
          win = window;

      if (en && en.sso) {
        code = track.value + "_pill";
        type = this.$el.getAttribute("data-type");
        en.sso.setVariant(type === "s" ? en.sso_variants.SIGN_UP : en.sso_variants.SIGN_IN);
        en.sso.setTrackingCode(code);
        en.sso.open();

        st.tdTrack("uiactions", {
          category: code + "_click",
          value: true,
          visitor_email_id: win.options.track.visitor_email_id,
          tracking_profile_id: win.options.track.tracking_profile_id
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

.box {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.desktop {
  display: none;
}

.mobile {
  display: block;
}

button {
  font-weight: 700;
  min-width: 25rem;
  border-radius: 4rem;
  transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out, background 0.25s ease-in-out;
  outline: 0;
  position: relative;
  background-color: $col_purple;
  border: .063rem solid $col_dpurple;
  box-sizing: border-box;
  box-shadow: 0 0.125rem 0.25rem $col_opmidblack;
  min-height: 2.5rem;
  cursor: pointer;
  padding: .625rem 1.25rem;

  &.mem,
  &.non {
    position: relative;
    min-width: unset;
    margin: 1.5rem auto 0;
    width: 100%;
  }

  &.mem {
    max-width: 21rem;
  }

  &.non {
    max-width: 30rem;
    padding-right: 6.625rem;
  }

  &:hover {
    background-color: lighten($col_purple, 10%);
  }

  &.ctr {
    margin-left: auto;
    margin-right: auto;
  }

  &.wide {
    width: 100%;
  }

  &, span {
    color: $col_white;
    font-size: 0.75rem;
  }

  .join {
    background-color: $col_dpurple;
    padding: 0.313rem 1rem;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: calc(50% - 0.75rem);
    right: .5rem;
    border-radius: 4rem;
  }

  &.non {
    text-align: left;
  }

  &.mem {
    background-color: $col_white;
    border: 0.063rem solid $col_dpurple;
    color: $col_purple;
    text-align: center;
  }
}

@media #{$desktop} {

  button.mem,
  button.non{
    position: absolute;
    transform: translate(0, 100%);
    margin: 0;
    left: 0;
    width: 100%;
    margin-top: 1.125rem;
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

}


</style>